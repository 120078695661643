import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyConversion',
  standalone: false,
})
export class MoneyPipe implements PipeTransform {
  constructor() {}

  transform(value: string, currency: string = 'USD'): string {
    const forceTwoDecimal = ['AFN', 'ALL'];
    let numberFormat: Intl.NumberFormatOptions = {
      style: 'currency',
      currency,
    };

    if (currency && currency !== '') {
      numberFormat = { style: 'currency', currency };
    }

    const currencyFractionDigits =
      new Intl.NumberFormat(navigator.language, numberFormat).resolvedOptions()
        .maximumFractionDigits || 0;

    let minimumFractionDigits = 2;
    let maximumFractionDigits = 2;

    if (!forceTwoDecimal.includes(currency)) {
      minimumFractionDigits = !currencyFractionDigits ? 0 : 2;
      maximumFractionDigits = currencyFractionDigits;
    }

    const formattedValue = isNaN(Number.parseFloat(value))
      ? ''
      : Number.parseFloat(value).toLocaleString(navigator.language, {
          minimumFractionDigits,
          maximumFractionDigits,
        });

    return formattedValue;
  }

  removeDuplicateCode(currencyCode: string, amount: string) {
    return amount.replace(currencyCode, '');
  }

  parse(value: string): string {
    return value.replace(/[^0-9.-]+/g, '');
  }
}
