import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConfigurationModule } from './runtime-configuration.module';

export interface RuntimeConfiguration {
  reportsPromotion?: {
    environment: string;
    promoteReports: boolean;
    createReport: boolean;
  };
  apiUrl: string;
  localApiUrl?: string;
  apiHostMode?: 'local' | 'remote';
  applicationInsightsInstrumentationKey?: string;
  dataCenterLocation?: string;
  calculations?: {
    enableTransactionSearchBehaviorToggle: boolean;
    enableDownloadPrePopulatedTaxAdjustmentFile: boolean;
  };
  authOptions: {
    apiUrl: string;
    enabled: false;
    loginPageUrl: string;
    logoutPageUrl: string;
    portalHomeUrl: string;
    portalHelpUrl: string;
    isUpperEnvironment: boolean;
    localSamlData?: string;
    requestRoles?: string[];
  };
}

@Injectable({ providedIn: RuntimeConfigurationModule })
export class RuntimeConfigurationService {
  private _config?: RuntimeConfiguration;

  constructor(private _httpClient: HttpClient) {}

  async init(): Promise<void> {
    try {
      this._config = await this._httpClient
        .get<RuntimeConfiguration>('/assets/public.json')
        .toPromise();
    } catch (e) {
      console.error('Error loading runtime configuration', e);
    }

    if (!this._config) {
      this._config = await this._httpClient
        .get<RuntimeConfiguration>('/assets/config.json')
        .toPromise();
    }
  }

  get(): RuntimeConfiguration {
    if (this._config == null) {
      throw new Error(
        'config is null, RuntimeConfigurationService needs to be initialized',
      );
    }
    return this._config;
  }
}
