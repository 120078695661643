import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { RegionNameService } from 'gain-lib/geography';

export type CountryDisplayFormat = 'name' | 'code' | 'name-code' | 'code';

export const defaultRegionDisplayFormat = 'name-code' as const;
@Component({
  selector: 'gax-region',
  templateUrl: './region.component.html',
  styleUrl: './region.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RegionComponent {
  countryCode = input.required<string>();
  regionCode = input.required<string>();
  format = input<CountryDisplayFormat | undefined | null>(
    defaultRegionDisplayFormat,
  );
  tooltip = input<boolean | undefined | null>();
  constructor(private rn: RegionNameService) {}

  protected textDisplayValue = computed(() =>
    this.getDisplayValue(
      this.regionCode(),
      this.format() ?? defaultRegionDisplayFormat,
    ),
  );

  protected tooltipDisplayValue = computed(() => {
    return this.tooltip() === true ||
      (this.tooltip() !== false && this.format() !== 'name-code')
      ? this.getDisplayValue(this.regionCode(), 'name-code')
      : null;
  });

  getDisplayValue(code: string, format: CountryDisplayFormat) {
    if (format === 'code') {
      return code;
    }
    return (
      this.rn.findRegionName(
        {
          regionCode: this.regionCode(),
          countryCode: this.countryCode(),
        },
        format.includes('code'),
      ) ?? code
    );
  }
}
