import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-run-calculation-employee-information',
  templateUrl: './run-calculation-employee-information.component.html',
  styleUrls: ['./run-calculation-employee-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RunCalculationEmployeeInformationComponent {
  @Input() firstName?: string | null | undefined;
  @Input() lastName?: string | null | undefined;
  @Input() employeeId?: string | null | undefined;
  @Input() transactionTitleId?: string | null | undefined;
}
