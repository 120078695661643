import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-table-dialog',
  templateUrl: './confirm-delete-table-dialog.component.html',
  styleUrls: ['./confirm-delete-table-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ConfirmDeleteTableDialogComponent {
  deleteText: string = '<p>Are you sure you want to delete the table row?</p>';

  constructor(@Inject(MAT_DIALOG_DATA) public data?: any) {
    if (data && data.deleteText) {
      this.deleteText = data.deleteText;
    }
  }
}
