import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';

export type PaddingWidth = 'none' | 'normal' | 'wide';

@Component({
  selector: 'gax-page-section-table-content',
  templateUrl: './page-section-table-content.component.html',
  styleUrl: './page-section-table-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PageSectionTableContentComponent {
  @ViewChild('tableViewport') tableViewport!: ElementRef;

  @Input()
  overridePadding!: string | undefined;

  @HostBinding('style.overflow-y')
  @Input()
  overflowY: 'auto' | 'hidden' = 'auto';

  normalPaddingInRem: number = 1.5;
  widePaddingInRem: number = 10.5;

  @Input()
  padding: PaddingWidth = 'normal';

  @HostBinding('style.padding')
  get sidePaddingInRem(): string {
    if (this.overridePadding) return this.overridePadding;
    if (this.padding === 'none') return `0 0.25rem 0.75rem`;

    if (this.padding === 'wide')
      return `0 ${this.widePaddingInRem}rem ${this.normalPaddingInRem}rem`;

    return `0 ${this.normalPaddingInRem}rem ${this.normalPaddingInRem}rem`;
  }

  // Use of marginTop here instead of paddingTop to fix scrollable table bug
  @HostBinding('style.marginTop.rem')
  @Input()
  paddingTopInRem: number = this.normalPaddingInRem;

  @HostBinding('style.max-height.vh')
  @Input()
  maxHeight: number | undefined;
}
