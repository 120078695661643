import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  TemplateRef,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type InformationSidePanelModalDialogData = {
  title: string;
  contentHtml?: string;
  template?: TemplateRef<unknown>;
};

@Component({
  selector: 'gax-information-side-panel-modal',
  templateUrl: './information-side-panel-modal.component.html',
  styleUrl: './information-side-panel-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InformationSidePanelModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected data: InformationSidePanelModalDialogData,
  ) {}
}
