import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'gax-table-zero-results-title',
  templateUrl: './table-zero-results-title.component.html',
  styleUrl: './table-zero-results-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TableZeroResultsTitleComponent {}
