/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MoneyPipe } from 'projects/gain-lib/money/src/money.pipe';

@Directive({
  selector: '[gaxMoneyFormatter]',
  standalone: false,
})
export class MoneyFormatterDirective implements OnInit, OnDestroy {
  private el: any;
  @Input() currencyCodeControl?: FormControl<string | undefined>;
  // @Input() currencyCode: string = '';
  currencyCodeSubscription?: Subscription;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: MoneyPipe,
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnDestroy() {
    this.currencyCodeSubscription?.unsubscribe();
  }

  private _currencyCode!: string;

  @Input() set currencyCode(value: string) {
    this._currencyCode = value;
    if (value?.length === undefined || value?.length < 3) return;
    this.el.value = this.currencyPipe.transform(
      this.currencyPipe.parse(this.el.value),
      value,
    );
  }

  get currencyCode(): string {
    return this._currencyCode;
  }

  ngOnInit() {
    this.el.value = this.currencyPipe.transform(
      this.el.value,
      this.currencyCode,
    );

    // if (!this.currencyCodeControl) return;

    // this.currencyCodeSubscription =
    //   this.currencyCodeControl.valueChanges.subscribe((_currencyCode) => {
    //     if (
    //       this.currencyCodeControl?.invalid ||
    //       _currencyCode?.length === undefined ||
    //       _currencyCode?.length < 3
    //     )
    //       return;
    //     this.el.value = this.currencyPipe.transform(
    //       this.currencyPipe.parse(this.el.value),
    //       _currencyCode,
    //     );
    //   });
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value: string) {
    this.el.value = this.currencyPipe.parse(value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    this.el.value = this.currencyPipe.transform(value, this.currencyCode);
  }
}
