import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { CountryNameService } from 'gain-lib/geography';

export type CountryDisplayFormat = 'name' | 'code' | 'name-code' | 'code';

export const defaultCountryDisplayFormat = 'name-code' as const;

@Component({
  selector: 'gax-country',
  templateUrl: './country.component.html',
  styleUrl: './country.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CountryComponent {
  countryCode = input.required<string>();
  format = input<CountryDisplayFormat | undefined | null>(
    defaultCountryDisplayFormat,
  );

  protected effectiveFormat = computed(() => {
    return this.format() ?? defaultCountryDisplayFormat;
  });
  constructor(private cd: CountryNameService) {}

  protected textDisplayValue = computed(() =>
    this.getDisplayValue(this.countryCode(), this.effectiveFormat()),
  );

  protected tooltipDisplayValue = computed(() => {
    const format = this.effectiveFormat();
    switch (format) {
      case 'name':
        return this.getDisplayValue(this.countryCode(), 'code');
      case 'code':
        return this.getDisplayValue(this.countryCode(), 'name');
      case 'name-code':
        return null;
    }
  });

  getDisplayValue(code: string, format: CountryDisplayFormat) {
    if (format === 'code') {
      return code;
    }
    return this.cd.findCountryName(code, format.includes('code')) ?? code;
  }
}
