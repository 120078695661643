import { ViewportScroller } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router, Scroll } from '@angular/router';
import { GaAuthService } from '@ga/central-auth-angular';
import { ProcessMonitor } from 'gain-lib/ga-process-monitor/process-monitor';
import { RouterPageViewService } from 'gain-lib/router/router-page-view.service';
import { MetricLoggerService } from 'gain-web/shared-modules/metric-logger/metric-logger.service';
import { RuntimeConfigurationService } from 'gain-web/shared-modules/runtime-configuration/runtime-configuration.service';
import { SessionTimeoutDialogComponent } from 'gain-web/shared-modules/session-timeout-dialog/session-timeout-dialog.component';
import { UserContextService } from 'gain-web/shared-modules/user/user-context.service';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import Unit = ApiClient.Unit;

type AuthFlow =
  | 'basicLoginWithFakeTokens'
  | 'basicLoginWithHardcodedSaml'
  | 'loggedInFromPortal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class AppComponent implements OnDestroy {
  onDestroy$ = new Subject();

  @ViewChild('topOfPage') topOfPageRef!: ElementRef<HTMLDivElement>;

  public title = 'GAIN 3.0';
  public password = '';
  public authorized$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = new BehaviorSubject<boolean>(false);
  private _authFlow: AuthFlow;

  navigation = new ProcessMonitor('PENDING');

  get showBasicLogin() {
    return (
      (this._authFlow === 'basicLoginWithFakeTokens' ||
        this._authFlow === 'basicLoginWithHardcodedSaml') &&
      !this.authorized
    );
  }

  get authorized() {
    return this.authorized$.getValue();
  }

  get isLoading() {
    return this.isLoading$.getValue();
  }

  constructor(
    router: Router,
    viewportScroller: ViewportScroller,
    metricLogger: MetricLoggerService,
    private authService: GaAuthService,
    private runtimeConfigService: RuntimeConfigurationService,
    private matDialog: MatDialog,
    public userContext: UserContextService,
    private _pageView$: RouterPageViewService,
  ) {
    // Log metrics
    metricLogger.flushOnMsInterval();

    this._pageView$.pageViews$
      .pipe(takeUntilDestroyed())
      .subscribe(async ({ start, end, routeParams, queryParams, ...props }) => {
        await metricLogger.logMetric({
          name: 'PageNavigationDurationInMs',
          namespace: 'GAIN/ServiceLevelIndicators',
          value: props.durationInMs,
          unit: Unit.MILLISECONDS,
          storageResolution: null,
          properties: { ...props, ...routeParams, ...queryParams },
        });
      });

    const authOptions = this.runtimeConfigService.get().authOptions;

    if (authOptions.enabled && authOptions.localSamlData) {
      this._authFlow = 'basicLoginWithHardcodedSaml';
    } else if (authOptions.enabled) {
      this._authFlow = 'loggedInFromPortal';
    } else {
      this._authFlow = 'basicLoginWithFakeTokens';
    }

    if (
      this._authFlow === 'basicLoginWithHardcodedSaml' ||
      this._authFlow === 'loggedInFromPortal'
    ) {
      this.initAuthFlowWithCentralAuthIntegration();
    } else if (this._authFlow === 'basicLoginWithFakeTokens') {
      this.initBasicAuthFlow();
    }

    if (this._authFlow === 'loggedInFromPortal' && !this.authorized) {
      window.location.href =
        this.runtimeConfigService.get().authOptions.loginPageUrl;
    }

    router.events
      .pipe(
        filter((e): e is Scroll => e instanceof Scroll),
        takeUntil(this.onDestroy$),
      )
      .subscribe((e: any) => {
        if (e.position) {
          // backward navigation
          viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          this.topOfPageRef?.nativeElement?.scrollIntoView();
        }
      });
  }

  initBasicAuthFlow() {
    this.authorized$.next(!!localStorage.getItem('authorized'));
  }

  initAuthFlowWithCentralAuthIntegration() {
    this.authService.isAuthorized$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.authorized$);

    this.authService.isSessionTimedOut$
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe((isSessionTimedOut) => {
        if (isSessionTimedOut) {
          this.matDialog.open(SessionTimeoutDialogComponent, {
            height: '350px',
            width: '500px',
            disableClose: true,
          });
        }
      });
  }

  async authenticate() {
    if (this._authFlow === 'loggedInFromPortal') {
      throw new Error(
        `This function shouldn't be called with the ${this._authFlow} auth flow`,
      );
    }

    if (this.password?.toLowerCase() === 'gesgain') {
      if (this._authFlow === 'basicLoginWithHardcodedSaml') {
        this.isLoading$.next(true);

        try {
          const saml =
            this.runtimeConfigService.get().authOptions.localSamlData;
          if (!saml) {
            throw new Error('Local SAML data not configured. Cannot login');
          }

          await this.authService.makeSamlRequest(saml);
        } catch (e) {
          alert('Saml request failed');
          console.error(e);
        } finally {
          this.isLoading$.next(false);
        }
      } else {
        this.authorized$.next(true);
        localStorage.setItem('authorized', 'true');
      }
    } else {
      alert('Password is incorrect.');
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
