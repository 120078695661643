import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnDestroy,
  OnInit,
  viewChild,
} from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MultiSelectHelper } from 'gain-lib/forms/src/multi-select-helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gax-select-all-option',
  templateUrl: './select-all-option.component.html',
  styleUrl: './select-all-option.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SelectAllOptionComponent<T> implements OnInit, OnDestroy {
  onDestroy$ = new Subject();
  multiSelect = input.required<MultiSelectHelper<T>>();
  private option = viewChild(MatOption);

  ngOnInit() {
    this.multiSelect()
      .allSelected$.pipe(takeUntil(this.onDestroy$))
      .subscribe((selected) => {
        if (selected) {
          this.option()?.select(false);
        } else {
          this.option()?.deselect(false);
        }
      });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
