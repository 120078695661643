import { Pipe, PipeTransform } from '@angular/core';
import { toFriendlyDuration } from 'gain-web/shared-modules/ga-date-time/toFriendlyDuration';

@Pipe({
  name: 'friendlyDuration',
  standalone: false,
})
export class FriendlyDurationPipe implements PipeTransform {
  transform(durationinMs: number) {
    return toFriendlyDuration(durationinMs);
  }
}
