import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import {
  EnumDisplayService,
  EnumValueDisplayFormat,
} from 'gain-web/lib/enums/enum-display.service';
import { titleCase } from 'gain-lib/text';
import PlanType = ApiClient.PlanType;

export type PlanTypeDisplayFormat = 'short name' | 'name';
@Component({
  selector: 'app-plan-type',
  template: `<span [matTooltip]="tooltipDisplayValue()">{{
    planTypeDisplay()
  }}</span>`,
  styleUrl: './plan-type.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PlanTypeComponent {
  planType = input.required<PlanType>();
  format = input<PlanTypeDisplayFormat>('name');
  tooltip = input<boolean>();

  protected planTypeDisplay = computed(() =>
    this.getDisplayValue(this.planType(), this.format()),
  );

  protected tooltipDisplayValue = computed(() => {
    return this.tooltip() === true ||
      (this.tooltip() !== false && this.format() !== 'name')
      ? this.getDisplayValue(this.planType(), 'name')
      : null;
  });

  private getDisplayValue(
    planType: ApiClient.PlanType,
    format: PlanTypeDisplayFormat,
  ): string {
    const enumFormat: EnumValueDisplayFormat = format
      ? 'display name'
      : 'short display name';
    return (
      this._enum.findEnumDisplay('PlanType', planType, enumFormat) ??
      titleCase(planType)
    );
  }

  constructor(private _enum: EnumDisplayService) {}
}
