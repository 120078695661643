import { Pipe, PipeTransform } from '@angular/core';
import { RegionNameService } from 'gain-lib/geography';

@Pipe({
  name: 'regionName',
  standalone: false,
})
export class RegionNamePipe implements PipeTransform {
  constructor(private _regionName: RegionNameService) {}

  transform(
    regionCode: string,
    countryCode: string[] | string | null,
    includeCode: boolean = false,
  ): string {
    if (countryCode == null) {
      return '';
    }
    return (
      this._regionName.findRegionName(
        { regionCode, countryCode },
        includeCode,
      ) ?? regionCode
    );
  }
}
