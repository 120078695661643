import { NestedTreeControl } from '@angular/cdk/tree';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {
  Event,
  NavigationEnd,
  PRIMARY_OUTLET,
  Router,
  UrlTree,
} from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface SideNode {
  name: string;
  children?: SideNode[];
  iconStatus?: string;
  showInfoIcon?: boolean;
  routePath?: string;
  icon?: string;
}

export interface SideNav {
  backNavItem?: SideNode;
  items: SideNode[];
}

@Component({
  selector: 'gax-side-navigation',
  styleUrls: ['./side-navigation.component.scss'],
  templateUrl: './side-navigation.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class SideNavigationComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() sideNav!: BehaviorSubject<SideNav>;
  sideNavSubscription?: Subscription;

  treeControl = new NestedTreeControl<SideNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<SideNode>();
  backNavItem?: SideNode;
  ancestors: BehaviorSubject<SideNode[]> = new BehaviorSubject<SideNode[]>([]);

  routerSubscription!: Subscription;
  ancestorSubscription!: Subscription;

  hasChild = (_: number, node: SideNode) =>
    !!node.children && node.children.length > 0;

  constructor(private router: Router) {
    this.routerSubscription = router.events
      .pipe(
        filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.ancestors.next(this.getAncestors());
      });
  }

  ngAfterViewInit(): void {
    this.ancestorSubscription = this.ancestors.subscribe((items) =>
      items.forEach((ancestor: SideNode) => {
        this.expandParentNavItem(ancestor);
      }),
    );
  }

  ngOnInit(): void {
    this.sideNavSubscription = this.sideNav.subscribe((nav) => {
      this.backNavItem = nav.backNavItem;
      this.dataSource.data = nav.items;
    });
  }

  expandParentNavItem(sideNode: SideNode) {
    this.treeControl.expand(sideNode);
  }

  getAncestors(): SideNode[] {
    const ancestors: SideNode[] = [];
    this.dataSource.data
      .filter((item) => item.children)
      .forEach((item) => {
        ancestors.push(...this.getParents(item));
      });
    return ancestors;
  }

  getParents(parentNode: SideNode): SideNode[] {
    const ancestors: SideNode[] = [];
    const urlTree: UrlTree = this.router.parseUrl(this.router.url);
    if (urlTree.root.children[PRIMARY_OUTLET]?.segments === undefined)
      return [];
    const routePath =
      urlTree.root.children[PRIMARY_OUTLET].segments[
        urlTree.root.children[PRIMARY_OUTLET].segments.length - 1
      ].path;
    parentNode.children?.every((childNode) => {
      if (
        childNode.routePath?.split('/')[
          childNode.routePath?.split('/').length - 1
        ] === routePath
      ) {
        ancestors.push(parentNode);
        return false;
      }
      ancestors.push(...this.getParents(childNode));
      return true;
    });
    return ancestors;
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
    this.ancestorSubscription?.unsubscribe();
    this.sideNavSubscription?.unsubscribe();
  }

  highlightActiveLink(node: SideNode): string {
    if (node.children) return '';
    return 'active';
  }

  @Input() getRoutePath(node: SideNode | undefined): string | null {
    if (!node || node.children) return null;
    return node.routePath ?? null;
  }
}
