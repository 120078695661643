import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

export type PaddingWidth = 'none' | 'normal' | 'wide' | 'full';

@Component({
  selector: 'gax-page-section-content',
  templateUrl: './page-section-content.component.html',
  styleUrl: './page-section-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PageSectionContentComponent implements OnInit {
  @Input()
  overridePadding?: string;

  normalPaddingInRem: number = 1.5;
  widePaddingInRem: number = 10.5;

  @Input()
  padding: PaddingWidth = 'normal';

  @HostBinding('style.padding')
  get sidePaddingInRem(): string {
    if (this.overridePadding) return this.overridePadding;
    if (this.padding === 'none') return `0 0.25rem 0.75rem`;

    if (this.padding === 'wide')
      return `0 ${this.widePaddingInRem}rem ${this.normalPaddingInRem}rem`;

    if (this.padding === 'full') return `0 20% ${this.normalPaddingInRem}rem`;

    return `0 ${this.normalPaddingInRem}rem ${this.normalPaddingInRem}rem`;
  }

  // Use of marginTop here instead of paddingTop to fix scrollable table bug
  @HostBinding('style.marginTop.rem')
  @Input()
  paddingTopInRem!: number;

  @HostBinding('style.maxHeight.vh')
  @Input()
  maxHeight: number | undefined;

  ngOnInit(): void {
    this.paddingTopInRem = this.overridePadding
      ? 0
      : this.paddingTopInRem
        ? this.paddingTopInRem
        : this.normalPaddingInRem;
  }
}
