import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo',
  standalone: false,
})
export class YesNoPipe implements PipeTransform {
  transform(value: boolean | unknown): 'Yes' | 'No' {
    if (value === true) {
      return 'Yes';
    }
    if (value === false) {
      return 'No';
    }
    return value != null ? 'Yes' : 'No';
  }
}
