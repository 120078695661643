import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ga-copy-text',
  templateUrl: './ga-copy-text.component.html',
  styleUrls: ['./ga-copy-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GaCopyTextComponent {
  @Input()
  textToCopy!: string;

  @Input()
  successMessage?: string;

  @Input()
  tooltip?: string;

  get tooltipText() {
    return this.tooltip ?? 'Click to copy to the clipboard.';
  }

  constructor(
    private readonly _clipboard: Clipboard,
    private _snackBar: MatSnackBar,
  ) {}

  copy() {
    const pendingCopy = this._clipboard.beginCopy(this.textToCopy);
    pendingCopy.copy();
    pendingCopy.destroy();
    if (this.successMessage != null) {
      this._snackBar.open(this.successMessage, undefined, {
        duration: 3000,
      });
    }
  }
}
