import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToSentence',
  standalone: false,
})
export class CamelCaseToSentencePipe implements PipeTransform {
  constructor() {}

  transform(value: string, titleCase: boolean = false): string {
    if (!value) return '';

    const valueWithSpaces = value
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .toLowerCase();

    if (titleCase)
      return valueWithSpaces.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

    const sentenceCase = valueWithSpaces.replace(/./, (c) => c.toUpperCase());

    return sentenceCase;
  }
}
