import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroupDirective,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'gax-money-amount-input',
  templateUrl: './money-amount-input.component.html',
  styleUrls: ['./money-amount-input.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MoneyAmountInputComponent implements OnInit {
  amountFormControlName = input.required<string>();
  amountControl!: FormControl<number>;
  requiredErrorMessage = input<string | null | undefined>('Amount required');
  numericErrorMessage = input<string | null | undefined>(
    'Amount must be a numerical value',
  );
  hint = input<string | null | undefined>();
  currencyCode = input.required<string>();

  constructor(private formGroupDirective: FormGroupDirective) {}

  ngOnInit() {
    this.amountControl = this.formGroupDirective.form.get(
      this.amountFormControlName(),
    ) as FormControl;

    this.amountControl.addValidators([
      Validators.min(0),
      Validators.maxLength(15),
      this.numericValidator(),
    ]);
  }

  private numericValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return isNaN(value) ? { numbersOnly: true } : null;
    };
  }
}
