import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  HostBinding,
  input,
  model,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PageEditTracker } from 'gain-lib/ga-page-editing/page-edit-tracker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gax-editable-cell-wrapper',
  templateUrl: './editable-cell-wrapper.component.html',
  styleUrl: './editable-cell-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EditableCellWrapperComponent
  implements OnChanges, OnInit, OnDestroy
{
  // Passing the saved value is only needed for exceptional cases where the initially provided value won't suffice.
  // This can be the case if the component gets re-rendered with modified (but unsaved) data (e.g. virtual scroll).
  savedValue = model<{ value: unknown }>();
  value = input.required<unknown>();
  onDestroy$ = new Subject<void>();
  private _saved: boolean = false;
  private _edited: boolean = false;
  private _recentlyEdited = false;
  private _previouslyEdited = false;

  protected sameAsSaved = computed(() => {
    return this.savedValue()?.value === this.value();
  });

  @HostBinding('class')
  get classes(): Record<string, boolean> {
    return {
      saved: this._saved,
      edited: this._edited,
      'previously-edited': this._previouslyEdited,
      'recently-edited': this._recentlyEdited,
      'same-as-saved': this.sameAsSaved(),
    };
  }

  constructor(
    private _cd: ChangeDetectorRef,
    private _pageEdits: PageEditTracker,
  ) {}

  ngOnInit() {
    this._pageEdits.saved$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((saved) => {
        if (saved) {
          this.markAsSaved();
        }
      });
  }

  public markAsEdited() {
    this.markAsUnsaved();
    this._edited = true;
    this._recentlyEdited = true;
    this._pageEdits.markAsEdited();
    this._cd.markForCheck();
    setTimeout(() => {
      this._recentlyEdited = false;
      this._cd.markForCheck();
    }, 1000);
  }

  public markAsUnsaved() {
    if (this._saved) {
      this._saved = false;
      this._cd.markForCheck();
    }
  }

  public markAsSaved() {
    this._previouslyEdited = this._edited;
    this._saved = true;
    this._edited = false;
    this.savedValue.set({
      value: this.value(),
    });
    setTimeout(() => {
      this._previouslyEdited = this._edited;
      this._cd.markForCheck();
    }, 3000);
    this._cd.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    const valueChanges = changes.value;
    if (valueChanges != null) {
      if (valueChanges.isFirstChange()) {
        const initialSavedValue = this.savedValue();
        if (
          initialSavedValue != null &&
          initialSavedValue.value !== this.value()
        ) {
          this._edited = true;
        } else {
          this.markAsSaved();
        }
      } else {
        this.markAsEdited();
      }
    }
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
