import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { CountryDisplayFormat } from 'gain-lib/geography/src/country/country.component';

@Component({
  selector: 'gax-country-inline-list',
  templateUrl: './country-inline-list.component.html',
  styleUrl: './country-inline-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CountryInlineListComponent {
  countryCodes = input.required<string[]>();
  format = input<CountryDisplayFormat | undefined | null>();
  summarizeCount = input<number | undefined | null>();

  summary = computed(() => {
    const countryCount = this.countryCodes().length;
    const summarizeCount = this.summarizeCount();
    if (summarizeCount != null && countryCount >= summarizeCount) {
      return countryCount === 1 ? '1 country' : `${countryCount} countries`;
    }
    return null;
  });
}
