import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'gax-table-error-message',
  templateUrl: './table-error-message.component.html',
  styleUrl: './table-error-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TableErrorMessageComponent {}
