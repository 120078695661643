import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Output,
  computed,
  input,
  model,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import BigNumber from 'bignumber.js';
import { DocumentEventService } from 'gain-lib/services/documentClickDetectionService.service';
import { GainMoneyModule } from 'gain-web/shared-modules/money/money.module';
import { Subscription } from 'rxjs';

export type ValueType = 'percent' | 'number' | 'string';

type AlignmentBehavior = 'left' | 'right';
type AlignmentBehaviorOptions = 'auto' | AlignmentBehavior;

@Component({
  selector: 'gax-table-cell-value-edit',
  templateUrl: './table-cell-value-edit.component.html',
  styleUrl: './table-cell-value-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    GainMoneyModule,
  ],
})
export class TableCellValueEditComponent {
  valueType = input<ValueType>('string');
  value = model.required<string | number | null>();
  protected inputValue = signal<string | number | null>(null);

  @Output()
  valueChange = new EventEmitter<string | number | null>();
  precisionDigits = input<number>(2);
  min = input<number | null>();
  max = input<number | null>();
  icon = input<string | null>();
  align = input<AlignmentBehaviorOptions>('auto');

  required = input<boolean>(false);

  @HostBinding('class.edited')
  edited?: boolean | undefined | null;

  @HostBinding('class.left-align')
  get leftAlign() {
    return this.alignmentBehavior === 'left';
  }

  get alignmentBehavior(): AlignmentBehavior {
    const option = this.align();
    if (option === 'auto') {
      return this.valueType() === 'number'
        ? // right aligned for numeric/money types unless otherwise specified
          'right'
        : // left aligned for other types unless otherwise specified
          'left';
    } else {
      return option;
    }
  }

  protected get hasCustomIcon() {
    return this.icon != null;
  }

  protected userEditableValue = computed(() => {
    const value = this.value();
    if (this.valueType() === 'percent' && value != null) {
      return new BigNumber(value).times(100).toNumber();
    }
    return value;
  });

  editing = signal<boolean>(false);

  @HostBinding('class.editing')
  get isEditing() {
    return this.editing();
  }

  _isClean = signal<boolean>(true);

  @HostBinding('class.clean')
  get isClean(): boolean {
    return this._isClean();
  }

  documentClickSubscription!: Subscription;

  computedErrors = computed(() => {
    const errors = [];

    const value = this.inputValue();

    const isRequired = this.required();
    const min = this.min();
    const max = this.max();

    if (value === null || value === undefined || value === '') {
      if (isRequired) {
        errors.push('Value required');
      }
    } else if (this.valueType() === 'string') {
      // any string validations here
    } else {
      // parse value as number
      const numberValue = parseFloat(value as string);

      // min validation
      if (typeof min === 'number' && numberValue < min) {
        errors.push('Value must be equal or greater than ' + min);
      }

      // max validation
      if (typeof max === 'number' && numberValue > max) {
        errors.push('Value must be less than ' + max);
      }
    }

    return errors;
  });

  computedErrorsTooltip = computed(() => {
    // check clean
    if (this._isClean()) {
      return null;
    }

    const errors = this.computedErrors();
    return errors.length > 0 ? errors.join(', ') : null;
  });

  constructor(
    private elRef: ElementRef,
    private documentEventService: DocumentEventService,
    private cd: ChangeDetectorRef,
  ) {
    this.documentClickSubscription = this.documentEventService.click$
      .pipe(takeUntilDestroyed())
      .subscribe((event) => {
        this.documentClickListener(event.target as HTMLElement);
      });
  }

  /**
   * Handles the click event outside of the component element.
   * If the target is inside the component element, it does nothing.
   * If the target is outside the component element, it calls the `exitEditMode` method.
   * @param target - The target element that triggered the click event.
   */
  documentClickListener(target: HTMLElement | undefined): void {
    if (target && this.elRef.nativeElement.contains(target)) {
      // console.log('Clicked inside');
    } else {
      // Clicked outside
      this.exitEditMode();
    }
  }

  onInputChange(inputChangeEvent: Event) {
    this._isClean.set(false);
    const target = inputChangeEvent.target as HTMLInputElement;

    const value = target.value;

    // check if inputType is a string
    if (this.valueType() === 'string') {
      this.inputValue.set(value === '' ? null : value);
      return;
    }

    const numberValue = parseFloat(value);

    // check if numberValue is not a number
    if (isNaN(numberValue)) {
      this.inputValue.set(null);
      return;
    }

    if (this.valueType() === 'percent') {
      if (numberValue > 100) {
        return;
      }
      this.inputValue.set(new BigNumber(numberValue).dividedBy(100).toNumber());
    } else {
      this.inputValue.set(numberValue);
    }

    this.inputValue.set(numberValue);
  }

  enterEditMode(element: HTMLDivElement) {
    this.editing.update(() => true);
    setTimeout(() => {
      const inputBox = element.querySelector('input');
      if (inputBox) inputBox.focus();
      this.cd.detectChanges();
      this.cd.markForCheck();
    }, 100);
  }

  exitEditMode(clearErrors = false) {
    if (clearErrors) {
      this._isClean.set(true);
    }

    this.editing.update(() => false);
    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  // Update row with new value amount
  updateValue(newValue: string, event: Event) {
    // check for errors
    let value = newValue as string | number | null;

    //This stops the expansion panel from opening after the parentRow is edited
    event.stopPropagation();

    // errors, do not update
    if (this.computedErrors().length > 0) {
      return;
    }

    if (this.valueType() !== 'string') {
      value = parseFloat(newValue);
    }

    this.inputValue.set(value);
    this.valueChange.emit(value);
    this.edited = true;
    this.exitEditMode();
  }
}
