import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'gax-required-asterisk',
  template: `*`,
  styleUrl: './ga-required-asterisk.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class GaRequiredAsteriskComponent {}
